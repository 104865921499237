import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/actzone',
    name: 'actzone',
    component: () => import('@/views/ActZone.vue'),
  },
  {
    path: '/actzone/detail',
    name: 'actzonedetail',
    component: () => import('@/views/ActZoneDetail.vue'),
  },
  {
    path: '/act/detail',
    name: 'actdetail',
    component: () => import('@/views/ActDetail.vue'),
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/article/list.vue'),
  },
  {
    path: '/article/detail',
    name: 'articledetail',
    component: () => import('@/views/article/detail.vue'),
  },
  {
    path: '/plant',
    name: 'plant',
    component: () => import('@/views/plant/list.vue'),
  },
  {
    path: '/plant/detail',
    name: 'plantdetail',
    component: () => import('@/views/plant/detail.vue'),
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/course/list.vue'),
  },
  {
    path: '/course/detail',
    name: 'coursedetail',
    component: () => import('@/views/course/detail.vue'),
  },
  {
    path: '/course/play',
    name: 'courseplay',
    component: () => import('@/views/course/play.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login.vue'),
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

export default router
