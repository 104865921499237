<template>
  <div class="map-box">
    <div id="map" style="width: 100%; height: 600px; margin: auto"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import 'echarts-gl'
export default defineComponent({
  name: 'HelloWorld',
  props: {
    chartData: Array
  },
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      this.ditu()
    })
  },
  methods: {
    ditu() {
      // 基于准备好的dom，初始化echarts实例
      let mapChart = document.getElementById('map')
      var myChart = echarts.init(mapChart)
      echarts.registerMap('henan', require(`./henan.json`))
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          extraCssText: 'color: #ffffff;background-color:#00000080;',
          formatter: function (params, ticket, callback) {
            let html = `<div>
                <div>
                  ${params.name}
                </div>
                <div class="chartHoverDiV">
                  ${params.data.jp || 2}门精品校本课程
                </div>
                <div class="chartHoverDiV">
                  ${params.data.beauty || 4}所省级最美乡村校园
                </div>
                <div class="chartHoverDiV">
                  ${params.data.sf || 4}所食育实践升级示范校
                </div>
                <div class="chartHoverDiV">
                  ${params.data.ld || 4}所劳动实践基地
                </div>
              </div>
                `

            return html + '<br />'
          }
        },
        series: [
          {
            data: this.chartData,
            type: 'map3D',
            map: 'henan',
            itemStyle: {
              color: '#E6E6E660', // 背景
              opacity: 1, //透明度
              borderWidth: 2, // 边框宽度
              borderColor: '#00FFE440' // 边框颜色
            },
            label: {
              show: true, //是否显示标签
              fontSize: 12,
              color: '#ffffff'
            },
            // 鼠标hover高亮
            emphasis: {
              label: {
                show: true //是否显示标签
              },
              itemStyle: {
                color: '#00FFE4'
              }
            },
            // boxDepth: 180,//地图倾斜度
            silent: false,
            regionHeight: 8,
            symbol: 'none',
            symbolSize: 50,
            viewControl: {
              beta: 0, //x轴旋转
              alpha: 75, //Y轴旋转
              zoomSensitivity: false, //是否开启缩放和平移/鼠标滑动缩放禁止
              rotateSensitivity: false, //禁止旋转地图
              // 下面的四个参数 可以实现禁止缩放地图
              projection: 'orthographic',
              orthographicSize: 105,
              maxOrthographicSize: 105,
              minOrthographicSize: 105
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-box {
  width: 50%;
}
</style>
