import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css';
import './assets/css/common.scss'
import VideoPlayer from '@videojs-player/vue'
// import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

// 引入 echarts 核心模块
import * as echarts from 'echarts/core';
// 引入 echarts-gl 模块
import { GlobeComponent } from 'echarts-gl/components';
 
// 引入 Canvas 渲染器
import { CanvasRenderer } from 'echarts/renderers';
import "echarts-gl"
// 注册必须的组件
echarts.use([GlobeComponent, CanvasRenderer]);

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(VideoPlayer)
app.use(router)
app.use(store)
app.mount('#app')


