// import Vue from "vue";
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    viewIdx: '', //当前组件
    keepComp: '',
    waitAssignSupervisor: [],
    waitAssignTrainer: [],
    userInfo: {},
    menus: [],
    menuList: [],
    activeMenu: 'org',
    layout: 1,
  },
  mutations: {
    viewIdx(state, data) {
      state.viewIdx = data
    },
    setActiveMenu(state, menu) {
      state.activeMenu = menu
    },
    setKeepComp(state, keepComp) {
      state.keepComp = keepComp
    },
    setWaitAssignSupervisor(state, waitAssignSupervisor) {
      state.waitAssignSupervisor = waitAssignSupervisor
    },
    setWaitAssignTrainer(state, waitAssignTrainer) {
      state.waitAssignTrainer = waitAssignTrainer
    },
    setMenus(state, menus) {
      state.menus = menus
      const menuList = []
      menus.forEach((m) => {
        menuList.push(m)
        if (m.childrenList && m.childrenList.length > 0) {
          m.childrenList.forEach((n) => {
            menuList.push(n)
          })
        }
      })
      state.menuList = menuList
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    exit(state) {
      localStorage.removeItem('token')
      state.userInfo = {}
      state.menus = []
      state.menuList = []
    },
    setLayout(state, layout) {
      state.layout = layout
    },
  },
  actions: {},

  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(state) {
        return {
          viewIdx: state.viewIdx,
          menus: state.menus,
          menuList: state.menuList,
          userInfo: state.userInfo,
          layout: state.layout,
        }
      },
    }),
    createPersistedState({
      storage: window.sessionStorage,
      reducer(state) {
        return {
          waitAssignSupervisor: state.waitAssignSupervisor,
          waitAssignTrainer: state.waitAssignTrainer,
        }
      },
    }),
  ],
})
