<template>
  <div class="home">
    <div class="home-banner" v-if="homeBanner.length > 0">
      <el-carousel :interval="4000" height="275px" trigger="click">
        <el-carousel-item v-for="item in homeBanner" :key="item.advId">
          <div class="banner-inner pointer" @click="open(item)">
            <img :src="item.httpUrl + item.advImg" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <section>
      <div class="info-box">
        <div style="flex: 1">
          <div class="title">关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守</div>
          <div class="text">
            关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守关于中小学生校园教育安全条例防守打法于中小学生校园教育安全条例防守…
          </div>
        </div>
        <div class="right-button">
          <el-button round>更多资讯</el-button>
        </div>
      </div>
    </section> -->

    <section v-if="actBanner && actBanner.length > 0">
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">活动专区</span>
        </div>
        <div class="right-button" @click="navto('actzone')">更多</div>
      </div>
      <div class="act-banner">
        <el-carousel :interval="4000" type="card" :show="5" height="310px" trigger="click" indicator-position="none">
          <el-carousel-item v-for="item in actBanner" :key="item.classifyId" @click="toActZone(item.classifyId)">
            <div class="banner-inner">
              <img :src="item.serverHttp + item.classifyIcon"/>
              <div class="text-box">
                <div class="title">{{ item.classifyName }}</div>
                <div class="info">
                  <div>
                    参与学校：<span>{{ item.schoolCnt }}所</span>
                  </div>
                  <div>
                    活动数量：<span>{{ item.activityCnt }}次</span>
                  </div>
                  <div>
                    上传记录：<span>{{ item.recordCnt }}条</span>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
    <section>
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">绿色地图</span>
        </div>
        <!-- <div class="right-button">更多</div> -->
      </div>
      <div style="display: flex; width: 100%">
        <henan-map :chartData="chartData" />
        <div style="width: 50%">
          <el-table :data="chartData" stripe style="width: 100%; height: 600px" :header-cell-style="{ background: '#fafafa' }">
            <el-table-column type="index" label="排名" align="center" width="60" />
            <el-table-column prop="name" label="地区" align="center" />
            <el-table-column prop="jp" label="校本课程" align="center" />
            <el-table-column prop="beauty" label="最美校园" align="center" />
            <el-table-column prop="sf" label="示范校" align="center" />
            <el-table-column prop="ld" label="劳动基地" align="center" />
          </el-table>
        </div>
      </div>
    </section>

    <section id="course" v-if="courseList.length>0">
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">耕种课程</span>
        </div>
        <div class="right-button" @click="navto('course')">更多</div>
      </div>
      <div class="course-box">
        <div class="course-item" v-for="item in courseList" :key="item.courseId" @click="navto('coursedetail', item.courseId)">
          <div class="course-cover-box">
            <div v-if="item.courseSpeaker" class="course-cover-label">
              <span id="wave-box" v-if="item.isLive">
                <span></span>
                <span></span>
                <span></span>
              </span>
              主讲人：{{ item.courseSpeaker }}
            </div>
            <img :src="item.serverHttp + item.courseImg" class="course-cover" />
          </div>
          <div class="course-info-box">
            <div style="flex: 1">
              <div class="course-info-title">
                {{ item.courseName }}
                <span id="wave-box" v-if="item.isLive">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div class="course-info-text">
                <!-- <img src="../assets/imgs/course-info-icon-time.png" class="course-info-icon" v-if="item.isLive" /> -->
                <img src="../assets/imgs/course-info-icon-person.png" class="course-info-icon" />
                <!-- <span v-if="item.isLive">开播时间：{{ item.startTime }}</span> -->
                <span>{{ item.courseViewnum }}人次已学习</span>
              </div>
            </div>
            <div>
              <!-- <span class="myPrimary" v-if="item.isLive">
                <el-button type="primary" round>进入直播</el-button>
              </span> -->
              <span class="myPrimary"> <el-button type="primary" round>学习课程</el-button> </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="green-baike" v-if="baikeList.length > 0">
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">绿植百科</span>
        </div>
        <div class="right-button" @click="navto('plant')">更多</div>
      </div>
      <div style="display: flex; align-items: center">
        <div class="baike-box">
          <div class="baike-item" v-for="item in baikeList" :key="item.id" @click="navto('plantdetail', item.id)">
            <div class="baike-cover-box">
              <img :src="item.serverHttp+item.themeCover" class="baike-cover" />
            </div>
            <div class="baike-info-box">
              <div style="flex: 1">
                <div class="baike-info-title">
                  {{ item.themeName }}
                </div>
                <!-- todo -->
                <!-- <div class="baike-info-text" v-html="item.themeInfo ? item.themeInfo.plantsInfo : ''">
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="baike-citiao">
          <div class="title">绿植百科</div>
          <div class="title2">走进绿色植物</div>
          <div class="citiao-item"><img src="../assets/imgs/baike-icon1.png" class="icon" /><span>{{ count }}</span>个词条</div>
          <!-- <div class="citiao-item"><img src="../assets/imgs/baike-icon2.png" class="icon" /><span>124</span>人学习</div> -->
          <!-- <div class="citiao-item"><img src="../assets/imgs/baike-icon3.png" class="icon" /><span>10</span>人贡献</div> -->
          <!-- <div class="citiao-button myPrimary"><el-button type="primary" round>申请创建词条</el-button></div> -->
        </div>
      </div>
    </section>

    <section v-if="middleBanner && middleBanner.length > 0" id="ai" class="pointer"  @click="open(middleBanner[0])">
      <img :src="middleBanner[0].httpUrl + middleBanner[0].advImg" class="ai-box" />
    </section>

    <section v-if="ldList.length > 0">
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">劳动实践基地</span>
        </div>
        <div class="right-button" @click="navto('article', 2)">更多</div>
      </div>
      <div>
        <div class="ld-box">
          <div class="ld-item" v-for="item in ldList" :key="item.id" @click="navto('articledetail', item.id)">
            <div class="ld-cover-box">
              <img :src="item.imgUrl" class="ld-cover" />
              <div class="ld-info-title">
                {{ item.articleTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="sfList.length > 0">
      <div class="home-title">
        <div>
          <img src="../assets/imgs/label-icon.png" class="icon" />
          <span class="text">示范案例</span>
        </div>
        <div class="right-button" @click="navto('article', 1)">更多</div>
      </div>
      <div>
        <div class="sf-box">
          <div class="sf-item" v-for="item in sfList" :key="item.id" @click="navto('articledetail', item.id)">
            <div class="sf-cover-box">
              <img :src="item.imgUrl" class="sf-cover" />
            </div>
            <div class="sf-info-box">
              <div style="flex: 1">
                <div class="sf-info-title">
                  {{ item.articleTitle }}
                </div>
                <div class="sf-info-text">
                  {{ item.articleOrigin }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import henanMap from '@/components/henanMap.vue' // @ is an alias to /src
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {queryAdvList, getClassifyList, getCourseList, getThemeList, queryArticleList} from '@/api/home.js'

const router = useRouter()
const colors = ['#12C74D', '#5AE986', '#8FF1AD', '#C9F9DA', '#EBFCF1', '#E6E6E6']
const chartData = reactive([
  {
    name: '郑州市',
    jp: 134,
    beauty: 35,
    sf: 65,
    ld: 10,
    itemStyle: { color: colors[0] }
  },
  {
    name: '开封市',
    jp: 78,
    beauty: 15,
    sf: 20,
    ld: 76,
    itemStyle: {}
  },
  {
    name: '洛阳市',
    jp: 129,
    beauty: 32,
    sf: 56,
    ld: 12,
    itemStyle: { color: colors[0] }
  },
  {
    name: '平顶山市',
    jp: 115,
    beauty: 30,
    sf: 48,
    ld: 90,
    itemStyle: { color: colors[1] }
  },
  {
    name: '安阳市',
    jp: 134,
    beauty: 35,
    sf: 65,
    ld: 10,
    itemStyle: { color: colors[1] }
  },
  {
    name: '鹤壁市',
    jp: 78,
    beauty: 15,
    sf: 20,
    ld: 76,
    itemStyle: {}
  },
  {
    name: '新乡市',
    jp: 78,
    beauty: 15,
    sf: 20,
    ld: 76,
    itemStyle: { color: colors[1] }
  },
  {
    name: '焦作市',
    jp: 78,
    beauty: 15,
    sf: 20,
    ld: 76,
    itemStyle: { color: colors[4] }
  },

  {
    name: '濮阳市',
    jp: 129,
    beauty: 32,
    sf: 56,
    ld: 12,
    itemStyle: { color: colors[2] }
  },
  {
    name: '许昌市',
    jp: 106,
    beauty: 25,
    sf: 34,
    ld: 85,
    itemStyle: { color: colors[1] }
  },
  {
    name: '漯河市',
    jp: 80,
    beauty: 16,
    sf: 27,
    ld: 78,
    itemStyle: { color: colors[2] }
  },
  {
    name: '三门峡市',
    jp: 98,
    beauty: 20,
    sf: 32,
    ld: 82,
    itemStyle: { color: colors[4] }
  },
  {
    name: '南阳市',
    jp: 98,
    beauty: 20,
    sf: 32,
    ld: 82,
    itemStyle: { color: colors[2] }
  },
  {
    name: '商丘市',
    jp: 115,
    beauty: 30,
    sf: 48,
    ld: 90,
    itemStyle: { color: colors[3] }
  },
  {
    name: '信阳市',
    jp: 109,
    beauty: 28,
    sf: 47,
    ld: 87,
    itemStyle: { color: colors[2] }
  },
  {
    name: '周口市',
    jp: 109,
    beauty: 28,
    sf: 47,
    ld: 87,
    itemStyle: { color: colors[3] }
  },
  {
    name: '驻马店市',
    jp: 106,
    beauty: 25,
    sf: 34,
    ld: 85,
    itemStyle: { color: colors[3] }
  },
  {
    name: '济源市',
    jp: 80,
    beauty: 16,
    sf: 27,
    ld: 78,
    itemStyle: { color: colors[4] }
  }
])
const homeBanner = ref<any[]>([])
const middleBanner = ref<any[]>([])
const calcIndicatorWidth = () => {
  document.documentElement.style.setProperty('--indicator-width', (homeBanner.value.length - 1) * 18 + 40 + 'px')
}
calcIndicatorWidth()
const actBanner = ref<any[]>([])
const courseList = ref<any[]>([])
const baikeList = ref<any[]>([])
const count = ref(0)
const ldList = ref<any[]>([])
const sfList = ref<any[]>([])
const navto = (routeName:string, id?:number) => {
  if(id) {
    router.push({
      name: routeName,
      query: {
        id
      }
    })
    return
  }
  router.push({
    name: routeName,
  })
}
const toActZone = (classifyId) => {
  router.push({
    name: 'actzonedetail',
    query: {
      id: classifyId,
    }
  })
}
// 获取示范案例
const getCase =() => {
  queryArticleList({typeId: 1, pageNum: 1, pageSize: 4, articleStatus: 1}).then(res => {
    if(res.data.code === '0'){
      sfList.value = res.data.data.dataList
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 获取劳动实践基地
const getBase =() => {
  queryArticleList({typeId: 2, pageNum: 1, pageSize: 5, articleStatus: 1}).then(res => {
    if(res.data.code === '0'){
      ldList.value = res.data.data.dataList
    }
  }).catch((err) => {
    console.error(err)
  })
}

// 获取绿色主题
const getTheme = () => {
  getThemeList({pageNum: 1, pageSize: 3}).then(res => {
    if(res.data.code === '0'){
      baikeList.value = res.data.data.dataList
      count.value = res.data.data.totalCount
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 获取课程列表
const getCourse = () => {
  getCourseList({curPage: 1, pageSize: 4,courseState: 1,courseLimit: 10,searchType:1}).then(res => {
    if(res.data.code === '0'){
      courseList.value = res.data.data.dataList
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 获取活动专区列表
const getActClassifyList = () => {
  getClassifyList().then(res => {
    if(res.data.code === '0'){
      actBanner.value = res.data.data
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 获取首页广告列表
const getAdv = () => {
  queryAdvList({
    apCode: 200
  }).then(res => {
    if(res.data.code === '0'){
      homeBanner.value = res.data.data
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 获取中间广告位
const getMiddleAdv = () => {
  queryAdvList({
    apCode: 201
  }).then(res => {
    if(res.data.code === '0'){
      middleBanner.value = res.data.data
    }
  }).catch((err) => {
    console.error(err)
  })
}
// 打开广告地址
const open =(adv) => {
  if(adv.advUrl && adv.advUrl !== '#') {
    window.open(adv.advUrl, '_blank')
  }
}

onMounted(() => {
  getAdv()
  getActClassifyList()
  getCourse()
  getTheme()
  getBase()
  getCase()
  getMiddleAdv()
})
</script>
<style scoped>
.banner-inner {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.banner-inner img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.act-banner {
  /* width: 80%; */
  margin: auto;
}
.act-banner .banner-inner {
  position: relative;
  /* width: 415px;
  height: 310px; */
}
.act-banner .banner-inner .text-box {
  position: absolute;
  left: 30px;
  bottom: 15px;
  width: 80%;
}
.act-banner .banner-inner .text-box .title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
.act-banner .banner-inner .text-box .info {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.act-banner .banner-inner .text-box .info span {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff99;
  margin-left: 10px;
}
.home-banner /deep/.el-carousel__container {
  border-radius: 12px;
  overflow: hidden;
}
/* .home-banner /deep/.el-carousel__indicators {
  width: var(--indicator-width);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 12px;
  background-color: #fff;
  list-style: none;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0 10px;
} */
.home-banner /deep/.el-carousel__indicator {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
  width: 8px;
  height: 8px;
  margin: 0 10px 0 0;
  padding: 0;
}
.home-banner /deep/.el-carousel__indicator:last-child {
  margin: 0;
}
.home-banner /deep/.el-carousel__indicator button {
  display: block;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #e7e7e7;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
}
.home-banner /deep/.el-carousel__indicators .is-active {
  width: 8px;
  height: 8px;
  background: #439F70;
  border-radius: 4px;
}
.home-banner /deep/.el-carousel__indicators .is-active button {
  opacity: 0;
}
.home-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0;
}
.home-title .icon {
  width: 34px;
  height: 28px;
  vertical-align: -15%;
}
.home-title .text {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-left: 10px;
}
.home-title .right-button {
  width: 63px;
  height: 35px;
  background: #0051e407;
  border-radius: 18px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0051e4;
  cursor: pointer;
}
.info-box {
  padding: 25px 20px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 20px 0px rgba(29, 62, 119, 0.05);
  margin-top: 25px;
  display: flex;
  align-items: center;
}
.info-box .title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
.info-box .text {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  height: 40px;
  overflow: hidden; /* 隐藏超出高度的内容 */
  display: -webkit-box; /* 使用弹性盒子布局 */
  -webkit-box-orient: vertical; /* 设置为垂直方向排列 */
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.info-box .right-button {
  margin-left: 70px;
}

.info-box .right-button /deep/ .el-button {
  border-color: #2c76f5;
  color: #2c76f5;
}
.course-box {
  display: flex;
}
.course-item {
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 7px 10px 0px rgba(29, 62, 119, 0.07);
  border-radius: 8px;
  overflow: hidden;
  width: 460px;
  height: 270px;
  transition: all 0.2s;
}
.course-item:last-child {
  margin-right: 0;
}
.course-item:hover {
  transform: scale(1.02);
  /* margin-right: 0; */
}
.course-cover-box {
  width: 100%;
  height: 175px;
  position: relative;
}
.course-cover-label {
  position: absolute;
  left: 10px;
  top: 15px;
  background: #00000038;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  border-radius: 16px;
}
.course-cover {
  width: 100%;
  height: 100%;
}
.course-info-box {
  padding: 15px 10px;
  display: flex;
  align-items: center;
}
.course-info-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.course-info-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 20px;
}
.course-info-icon {
  width: 13px;
  height: 13px;
  vertical-align: -8%;
  margin-right: 5px;
}
.myPrimary /deep/ .el-button {
  background: #2c76f5;
  border-color: #2c76f5;
}
.myPrimaryWhite /deep/ .el-button {
  background: #fff;
  border-color: #2c76f5;
  color: #2c76f5;
}
.baike-box {
  display: flex;
}
.baike-item {
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 7px 10px 0px rgba(29, 62, 119, 0.07);
  border-radius: 12px;
  overflow: hidden;
  width: 310px;
  height: 300px;
  transition: all 0.2s;
  cursor: pointer;
}
.baike-item:last-child {
  margin-right: 0;
}
.baike-item:hover {
  transform: scale(1.02);
  /* margin-right: 0; */
}
.baike-cover-box {
  width: 100%;
  height: 227px;
  position: relative;
}
.baike-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.baike-info-box {
  padding: 20px 15px;
}
.baike-info-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.baike-info-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
  line-height: 20px;
  height: 60px;
  overflow: hidden; /* 隐藏超出高度的内容 */
  display: -webkit-box; /* 使用弹性盒子布局 */
  -webkit-box-orient: vertical; /* 设置为垂直方向排列 */
  -webkit-line-clamp: 3; /* 指定显示的行数 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.baike-citiao {
  margin: 0 80px;
  text-align: center;
}
.baike-citiao .title {
  font-size: 30px;
  font-weight: bold;
  color: #2c76f5;
}
.baike-citiao .title2 {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}
.baike-citiao .citiao-item {
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.baike-citiao .citiao-item .icon {
  width: 35px;
  height: 35px;
}
.baike-citiao .citiao-item span {
  font-size: 34px;
  font-weight: bold;
  color: #2c76f5;
  display: inline-block;
  width: 80px;
  text-align: center;
}
.baike-citiao .citiao-button {
  margin-top: 50px;
}
.baike-citiao .citiao-button /deep/ .el-button {
  width: 100%;
  height: 40px;
  border-radius: 20px;
}
.ai-box {
  width: 100%;
  /* width: 1420px; */
  height: 202px;
  margin-top: 40px;
}

.ld-box {
  display: flex;
}
.ld-item {
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 7px 10px 0px rgba(29, 62, 119, 0.07);
  border-radius: 12px;
  overflow: hidden;
  width: 268px;
  height: 215px;
  transition: all 0.2s;
  cursor: pointer;
}
.ld-item:last-child {
  margin-right: 0;
}
.ld-item:hover {
  transform: scale(1.02);
  /* margin-right: 0; */
}
.ld-cover-box {
  width: 100%;
  height: 215px;
  position: relative;
}
.ld-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ld-info-title {
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  left: 15px;
  bottom: 25px;
  width: 200px;
  color: #ffffff;
  overflow: hidden; /* 隐藏超出高度的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  white-space: nowrap;
}
.sf-box {
  display: flex;
}
.sf-item {
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 7px 10px 0px rgba(29, 62, 119, 0.07);
  border-radius: 12px;
  overflow: hidden;
  width: 340px;
  height: 350px;
  transition: all 0.2s;
  cursor: pointer;
}
.sf-item:last-child {
  margin-right: 0;
}
.sf-item:hover {
  transform: scale(1.02);
  /* margin-right: 0; */
}
.sf-cover-box {
  width: 100%;
  height: 252px;
  position: relative;
}
.sf-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sf-info-box {
  padding: 25px 15px;
}
.sf-info-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  overflow: hidden; /* 隐藏超出高度的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  white-space: nowrap;
}
.sf-info-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
  overflow: hidden; /* 隐藏超出高度的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  white-space: nowrap;
}
</style>
