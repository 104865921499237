export const getFileType = function (fileName){
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result = '';
  try {
      const flieArr = fileName.split('.');
      suffix = flieArr[flieArr.length - 1];
  } catch (err) {
      suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) { return false; }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
      return 'image';
  }
  // 匹配txt
  const txtlist = ['txt'];
  result = txtlist.find(item => item === suffix);
  if (result) {
      return 'txt';
  }
  // 匹配 excel
  const excelist = ['xls', 'xlsx'];
  result = excelist.find(item => item === suffix);
  if (result) {
      return 'excel';
  }
  // 匹配 word
  const wordlist = ['doc', 'docx'];
  result = wordlist.find(item => item === suffix);
  if (result) {
      return 'word';
  }
  // 匹配 pdf
  const pdflist = ['pdf'];
  result = pdflist.find(item => item === suffix);
  if (result) {
      return 'pdf';
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx'];
  result = pptlist.find(item => item === suffix);
  if (result) {
      return 'ppt';
  }
  // 匹配 视频
  const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
  result = videolist.find(item => item === suffix);
  if (result) {
      return 'video';
  }
  // 匹配 音频
  const radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.find(item => item === suffix);
  if (result) {
      return 'radio';
  }
  // 其他 文件类型
  return 'other';
}
export const convertNumberToChinese = (number) => {
  // 定义一个数组，存储中文数字
  const chinese_numbers = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
  ]

  // 定义一个数组，存储中文单位
  const chinese_units = ['', '十', '百', '千', '万', '亿', '兆']

  // 将数字转换为字符串
  const number_string = number.toString()

  // 将字符串中的每个数字转换为中文数字
  let chinese_number_string = ''
  for (let i = 0; i < number_string.length; i++) {
    const digit = number_string[i]
    const unit = chinese_units[number_string.length - i - 1]
    if (digit === '0' && i !== 0) {
      if (
        chinese_number_string[chinese_number_string.length - 1] !== '零' &&
        unit !== ''
      ) {
        chinese_number_string += '零'
      }
    } else {
      chinese_number_string += chinese_numbers[parseInt(digit)] + unit
    }
  }

  // 返回转换后的字符串
  return chinese_number_string.replace(/^一十/, '十')
}
export function convertObjToQueryString(object) {
  // 定义一个数组，存储query字符串的各个部分
  const query_string_parts = []

  // 遍历对象中的所有键值对
  for (const key in object) {
    // 将键值对添加到query字符串的各个部分中
    query_string_parts.push(`${key}=${object[key]}`)
  }

  // 将query字符串的各个部分连接起来
  const query_string = query_string_parts.join('&')

  // 返回query字符串
  return query_string
}
