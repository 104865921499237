/**
 * 引入POST、BASE_URL
 * @param params
 * @returns {*}
 */
import { POST, BASE_URL, down, DOWN } from '../api/index'
import { convertObjToQueryString } from '@/utils/index.js'
export const fileuPicture = BASE_URL + '/sun/auth/fileUpload/uploadFile'
// 获取课时详情
export function courselesson(params) {
  return POST(
    BASE_URL + `/future/lesson/get-detail`,
    params,
    'get',
    '',
  )
}
//资源-下载次数+1
export function setdown(params) {
  return POST(BASE_URL + "/evaluate/res/set-down", params, "post", "", 2);
}
// 获取字典
export function dictcode(params) {
  return POST(
    BASE_URL + `/evaluate/dict/code`,
    params,
    'get',
    '',
  )
}
// 获取课程详情
export function getCourseDetail(params) {
  return POST(
    BASE_URL + `/future/course/getDetail`,
    params,
    'get',
    '',
  )
}
// 增加浏览量
export function previewNum(params) {
  return POST(
    BASE_URL + `/evaluate/group/article/previewNum`,
    params,
    'get',
    '',
  )
}
// 获取单独的资讯
export function getArticle(params) {
  return POST(
    BASE_URL + `/evaluate/group/article/getArticle`,
    params,
    'get',
    '',
  )
}
// 活动专题-查看专题下的活动列表
export function getActivityRecord(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-activity-record`,
    params,
    'get',
    '',
  )
}
// 活动专题-查看专题下的活动列表
export function queryActivityInfo(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-activity-info`,
    params,
    'get',
    '',
  )
}
// 活动专题-查看专题下的活动列表
export function queryActivityList(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-activity-list`,
    params,
    'get',
    '',
  )
}
// 活动专区-查询专题详情
export function getClassifyInfo(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-classify-info`,
    params,
    'get',
    '',
  )
}
// 活动专区-查询总的统计
export function getClassifySite(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-classify-site`,
    params,
    'get',
    '',
  )
}
// 分页查询资讯
export function queryArticleList(params) {
  return POST(
    BASE_URL + `/evaluate/group/article/queryArticleList`,
    params,
    'get',
    '',
  )
}
// 绿植详情
export function getThemeDetail(params) {
  return POST(
    BASE_URL + `/evaluate/theme/detail`,
    params,
    'get',
    '',
  )
}
// 绿植列表
export function getThemeList(params) {
  return POST(
    BASE_URL + `/evaluate/theme/detail/list`,
    params,
    'get',
    '',
  )
}
// 获取课程列表
export function getCourseList(params) {
  return POST(
    BASE_URL + `/future/course/list`,
    params,
    'get',
    '',
  )
}
// 活动专区-查询专题统计列表
export function getClassifyList(params) {
  return POST(
    BASE_URL + `/evaluate/activity/open/get-classify-list`,
    params,
    'get',
    '',
  )
}
// 获取首页广告
export function queryAdvList(params) {
  return POST(
    BASE_URL + `/evaluate/adv/getAdvList`,
    params,
    'get',
    '',
  )
}

// ----------------------------------------------------------------
// 账号密码登录
export function login(params) {
  return POST(BASE_URL + `/evaluate/user/login`, params, 'post', '')
}
// 获取用户token信息
export function getTokenInfo(params) {
  return POST(
    BASE_URL + `/user/search/users/get-token-info`,
    { clientType: 3 },
    'get',
    '',
  )
}
// 切换机构
export function switchOrg(params) {
  return POST(BASE_URL + `/user/auth/users/switch`, params, 'post', '')
}
// 修改用户密码
export function updatePwd(params) {
  return POST(BASE_URL + `/user/auth/users/pwd/update`, params, 'post', '')
}
// 根据角色code查询模块列表(用户登录时使用)
export function getListRole(params) {
  return POST(BASE_URL + `/user/search/roles/getListRole`, params, 'get', '')
}
// 获取当前用户的待办事项
export function getBackLogList(params) {
  return POST(BASE_URL + `/sun/auth/backLog/getBackLogList`, params, 'get', '')
}
// 获取学生的群组成员
export function getFlockRankByStudentId(params) {
  return POST(
    BASE_URL + `/sun/auth/flock/getFlockRankByStudentId`,
    params,
    'get',
    '',
  )
}
// 查询学生阶段反馈列表
export function termStudentList(params) {
  return POST(BASE_URL + `/sun/search/term/termStudentList`, params, 'get', '')
}
// 训练视频分页列表
export function planVideoPagePc(params) {
  return POST(BASE_URL + `/sun/search/plan/planVideoPagePc`, params, 'get', '')
}
//学员详情-打卡记录-某个记录的打卡详情 /user/search/plan/getPlanCycleRecordList
export function getPlanCycleRecordList(params) {
  return POST(
    BASE_URL + `/sun/search/plan/getPlanCycleRecordList`,
    params,
    'get',
    '',
  )
}
//学员详情-打卡记录
export function getPlanCycleList(params) {
  return POST(BASE_URL + `/sun/search/plan/getPlanCycleList`, params, 'get', '')
}
// 根据计划id获取训练方案树形结构
export function getProgrammeByPlanId(params) {
  return POST(BASE_URL + `/sun/search/plan/getProgrammePc`, params, 'get', '')
}
// 获取学员的训练计划列表
export function getPayPlanList(params) {
  return POST(BASE_URL + `/sun/search/plan/getPayPlanList`, params, 'get', '')
}
// 导出费用统计
export function exportPayRecordStatistics(params, name) {
  return down(
    BASE_URL +
      '/sun/auth/payRecord/exportPayRecordStatistics?' +
      convertObjToQueryString(params),
    'get',
    name,
    '1',
  )
}
// 费用统计
export function payRecordStatistics(params) {
  return POST(
    BASE_URL + `/sun/auth/payRecord/payRecordStatistics`,
    params,
    'get',
    '',
  )
}
// 导出学员统计
export function exprotStudentStatistics(params, name) {
  return down(
    BASE_URL +
      '/user/search/students/exprotStudentStatistics?' +
      convertObjToQueryString(params),
    'get',
    name,
    '1',
  )
}
// 学员统计
export function studentStatistics(params) {
  return POST(
    BASE_URL + `/user/search/students/studentStatistics`,
    params,
    'get',
    '',
  )
}
// 延长训练结束时间
export function extendTime(params) {
  return POST(BASE_URL + `/sun/auth/plan/extendTime`, params, 'post', '')
}
// 训练方案历史记录
export function programmeHistoryList(params) {
  return POST(
    BASE_URL + `/sun/search/plan/programmeHistoryList`,
    params,
    'get',
    '',
  )
}
// 学员训练方案定制
export function setStudentProgramme(params) {
  return POST(
    BASE_URL + `/sun/auth/plan/setStudentProgramme`,
    params,
    'post',
    '',
  )
}
// 获取核心能力树形结构（只返回包含训练项目的能力）
export function getAbilityTreePc(params) {
  return POST(
    BASE_URL + `/sun/auth/ability/getAbilityTreePc`,
    params,
    'get',
    '',
  )
}
// 获取核心能力树形结构（包含训练项目）
export function getAbilityTreeList(params) {
  return POST(
    BASE_URL + `/sun/auth/ability/getAbilityTreeList`,
    params,
    'get',
    '',
  )
}
// 根据计划id获取训练方案列表
export function getProgrammeList(params) {
  return POST(BASE_URL + `/sun/search/plan/getProgrammeList`, params, 'get', '')
}
// 根据训练计划id查询训练计划详情
export function getPlanInfo(params) {
  return POST(BASE_URL + `/sun/search/plan/getPlanInfo`, params, 'get', '')
}
// 学员计划列表查询（PC端定制方案模块）
export function supervisorProgrammeList(params) {
  return POST(
    BASE_URL + `/user/search/students/supervisorProgrammeList`,
    params,
    'get',
    '',
  )
}
// 获取项目详情
export function getItem(params) {
  return POST(BASE_URL + `/sun/auth/item/getItem`, params, 'get', '')
}
// 删除项目
export function delItem(params) {
  return POST(BASE_URL + `/sun/auth/item/delItem`, params, 'get', '')
}
// 修改项目状态
export function modifyItemEnable(params) {
  return POST(BASE_URL + `/sun/auth/item/isEnable`, params, 'get', '')
}
// 添加-修改项目
export function addItem(params) {
  return POST(BASE_URL + `/sun/auth/item/addItem`, params, 'post', '')
}
// 分页查询项目列表 /user/auth/item/pagerList
export function getProjectList(params) {
  return POST(BASE_URL + `/sun/auth/item/pagerList`, params, 'get', '')
}
// 核心能力重新排序
export function resetAbility(params) {
  return POST(BASE_URL + `/sun/auth/ability/reset`, params, 'post', '')
}
// 删除核心能力
export function delAbility(params) {
  return POST(BASE_URL + `/sun/auth/ability/delAbility`, params, 'get', '')
}
// 获取能力详情
export function getAbility(params) {
  return POST(BASE_URL + `/sun/auth/ability/getAbility`, params, 'get', '')
}
// 添加-修改核心能力
export function addAbility(params) {
  return POST(BASE_URL + `/sun/auth/ability/addAbility`, params, 'post', '')
}
// 获取核心能力
export function abilityTreeList(params) {
  return POST(BASE_URL + `/sun/auth/ability/abilityTreeList`, params, 'get', '')
}
// 分配训练师
export function allocationTrainer(params) {
  return POST(
    BASE_URL + `/user/auth/students/allocationTrainer`,
    params,
    'post',
    '',
  )
}
// 获取某个机构下的训练师 /user/search/users/trainerUserList
export function trainerUserList(params) {
  return POST(
    BASE_URL + `/user/search/users/trainerUserList`,
    params,
    'get',
    '',
  )
}
// 分配督导师
export function allocationSupervisor(params) {
  return POST(
    BASE_URL + `/user/auth/students/allocationSupervisor`,
    params,
    'post',
    '',
  )
}
// 获取督导师
export function getSupervisorList(params) {
  return POST(
    BASE_URL + `/user/search/users/getSupervisorList`,
    params,
    'get',
    '',
  )
}
// 学员列表分页查询（pc端分配督导师）
export function supervisorList(params) {
  return POST(
    BASE_URL + `/user/search/students/supervisorList`,
    params,
    'get',
    '',
  )
}
// 学员基本信息
export function baseStudentDetail(params) {
  return POST(
    BASE_URL + `/user/search/students/baseStudentDetail`,
    params,
    'get',
    '',
  )
}
// 取消毕业 /user/auth/students/cancelStudentGraduated
export function cancelStudentGraduated(params) {
  return POST(
    BASE_URL + `/user/auth/students/cancelStudentGraduated`,
    params,
    'get',
    '',
  )
}
// 学员毕业
export function studentGraduated(params) {
  return POST(
    BASE_URL + `/user/auth/students/studentGraduated`,
    params,
    'get',
    '',
  )
}
// 学员列表分页查询（PC端学员管理）
export function studentPage(params) {
  return POST(BASE_URL + `/user/search/students/studentPage`, params, 'get', '')
}
// 导出费用明细
export function exportPayRecordPage(params, name) {
  return down(
    BASE_URL +
      '/sun/auth/payRecord/exportPayRecordPage?' +
      convertObjToQueryString(params),
    'get',
    name,
    '1',
  )
}
// 费用明细
export function payRecordPage(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/payRecordPage`, params, 'get', '')
}
// 查询缴费和退费详情
export function getPayRecordDetail(params) {
  return POST(
    BASE_URL + `/sun/auth/payRecord/getPayRecordDetail`,
    params,
    'get',
    '',
  )
}
// 删除退费
export function delRefund(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/delRefund`, params, 'get', '')
}
// 删除缴费
export function delPayRecord(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/delPayRecord`, params, 'get', '')
}
//获取学员的缴费记录
export function payRecordList(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/payRecordList`, params, 'get', '')
}
//添加-修改退费记录
export function addPayRecordRefund(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/refund`, params, 'post', '')
}
// 添加-修改缴费
export function addPayRecord(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/addPayRecord`, params, 'post', '')
}
// 获取学员最近的缴费期数
export function getLastPayNum(params) {
  return POST(BASE_URL + `/sun/auth/payRecord/getLastPayNum`, params, 'get', '')
}
// 学员列表分页查询(pc端缴费管理-修改训练师)
export function studentPayList(params) {
  return POST(
    BASE_URL + `/user/search/students/studentPayList`,
    params,
    'get',
    '',
  )
}
// 分页查询用户列表
export function getPageUserList(params) {
  return POST(BASE_URL + `/user/search/users/page`, params, 'get', '')
}
// 设置权限
export function assignPermis(params) {
  return POST(BASE_URL + `/user/auth/roles/assignPermis`, params, 'post', '')
}
// 查询角色权限菜单
export function getModuleByRole(params) {
  return POST(
    BASE_URL + `/user/search/roles/getModuleByRole`,
    params,
    'get',
    '',
  )
}
// 查询角色列表
export function getRoleList() {
  return POST(BASE_URL + `/user/search/roles/all`, '', 'get', '')
}
// 查询用户详情
export function getUserInfo(params) {
  return POST(BASE_URL + `/user/search/users/userInfo`, params, 'get', '')
}
// 查询当前用户可创建的角色
export function getCreateRoles(params) {
  return POST(BASE_URL + `/user/search/roles/search-by-user`, params, 'get', '')
}
// 机构添加用户
export function addOrgUser(params) {
  return POST(BASE_URL + `/user/auth/users/admin/add`, params, 'post', '')
}
// 修改用户状态
export function modifyUserEnable(params) {
  return POST(
    BASE_URL + `/user/auth/users/modifyUserEnable`,
    params,
    'post',
    '',
  )
}
// 重置用户密码
export function resetUserPass(userId) {
  return POST(
    BASE_URL + `/user/auth/users/reset/${userId}/pwd`,
    '',
    'patch',
    '',
  )
}
// 删除用户
export function delUser(params) {
  return POST(BASE_URL + `/user/auth/users/del`, params, 'post', '')
}
// 分页查询用户列表（机构下的用户）
export function getUserList(params) {
  return POST(BASE_URL + `/user/search/users/userPageList`, params, 'get', '')
}
// 获取机构详情
export function getOrgInfo(orgId) {
  return POST(BASE_URL + `/user/search/schools/${orgId}/info`, '', 'get', '')
}
// 删除机构
export function delOrg(orgId) {
  return POST(BASE_URL + `/user/auth/schools/${orgId}/del`, '', 'delete', '')
}
// 编辑机构
export function editOrg(orgId, params) {
  return POST(
    BASE_URL + `/user/auth/schools/${orgId}/edit`,
    params,
    'patch',
    '',
  )
}
// 添加机构
export function addOrg(params) {
  return POST(BASE_URL + '/user/auth/schools/add', params, 'post', '')
}
// 查询总部机构
export function getHeadQuarters(params) {
  return POST(
    BASE_URL + '/user/search/schools/getHeadQuarters',
    params,
    'get',
    '',
  )
}
// 查询可创建的机构类型
export function getOrgTypes(params) {
  return POST(BASE_URL + '/user/search/schools/types', params, 'get', '')
}
// 查询区域
export function getArea(params) {
  return POST(BASE_URL + '/user/search/areas/list', params, 'get', '')
}
// 查询字典值
export function dict(params) {
  return POST(BASE_URL + '/user/search/dict/search', params, 'get', '')
}
// 查询机构树
export function getOrgTree(params) {
  return POST(BASE_URL + '/user/search/schools/tree', params, 'get', '')
}
