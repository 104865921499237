/* eslint-disable */
import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = ElMessage(options)
}
;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
/**
 * 定义请求常量
 * TIME_OUT、ERR_OK
 */
export const TIME_OUT = 88888888 // 请求超时时间
export const ERR_OK = '0' // 请求成功返回状态，字段和后台统一
export const ERR_RELOGIN = 401 //重新登录状态
export const token = localStorage.getItem('token')
export const BASE_URL = 'https://testapi.lsxy.51xiaoping.com'
axios.defaults.timeout = TIME_OUT
import store from '@/store/index.ts'
// 封装请求拦截
axios.interceptors.request.use(
  (config) => {
    // store.commit("active", true);
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// 封装响应拦截，判断token是否过期
axios.interceptors.response.use(
  (response) => {
    setTimeout(() => {
      // store.commit("active", false);
    }, 200)
    if (response.data.code == '401') {
      //token失效状态判断，可跳转
      ElMessage(response.data.msg)
      return false
    } else if (response.data.code == 0 || response.data.code == 200) {
      Interceptor(response.data.code)
      return response
    } else {
      ElMessage({
        message: response.data.msg,
        type: 'info',
      })
      return false
    }
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      ElMessage({ message: 'token失效', grouping: true })
      // location.href = 'http://edu.hn-dg.com.cn/#/register'
      return false
    } else {
      ElMessage({ message: '请求失败', grouping: true })
    }
  },
)

//拦截器状态
function Interceptor(i) {
  switch (i) {
    case 401:
      localStorage.clear()
      location.href = '/register'
      break
  }
}

export default axios

// 封装post请求(requestUrl：域名公共部分,params：参数列表type:token类型ctype:上传数据格式类型)
export function POST(requestUrl, params = '', post, type, ctype) {
  //请求方式
  let method = post ? post : 'post'

  //默认登录token
  let token = window.localStorage.getItem('token')

  //token类型
  switch (type) {
    case 1: //注册token暂时没有用处，咱们系统没有注册流程
      token = window.localStorage.getItem('token')
      break
  }
  token = token != null ? 'Bearer' + ' ' + token : ''

  //post请求
  if (method == 'post' || method == 'patch') {
    //设置Content-Type
    if (ctype == 1) {
      return axios({
        url: requestUrl,
        method: method,
        data: params,
        headers: {
          Authorization: token,
          'Content-Type': ctype,
        },
      })
    } else if (ctype == 2) {
      return axios({
        url: requestUrl,
        method: method,
        params: params,
        headers: {
          Authorization: token,
        },
      })
    } else if (ctype == 4) {
      return axios({
        url: requestUrl,
        method: method,
        params: params,
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      })
    } else if (ctype == 3) {
      return axios({
        url: requestUrl,
        method: method,
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: token,
        },
      })
    } else {
      return axios({
        url: requestUrl,
        method: method,
        data: params,
        headers: {
          Authorization: token,
          'Content-Type': ctype,
        },
      })
    }
  } else {
    //get请求
    return axios({
      url: requestUrl,
      method: method,
      params: params,
      headers: {
        Authorization: token,
        'Content-Type': ctype,
      },
    })
  }
}

export function down(url, method, name, states, params) {
  let Loading
  Loading = ElLoading.service({
    lock: true,
    fullscreen: true,
    text: '数据处理中，请稍后！！！',
  })
  //默认登录token
  let token = window.localStorage.getItem('token')
  if (method == 'get') {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true) // 也可以使用POST方式，根据接口
    xhr.responseType = 'blob' // 返回类型blob
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    // 发送ajax请求
    xhr.send()
    xhr.onload = function () {
      // 请求完成
      if (this.status == 200 || this.status == 201) {
        // 返回201
        if (this.response.size < 150) {
          Loading.close()
          resetMessage({
            message: '暂无数据',
          })
          return false
        }
        var blob = this.response
        var reader = new FileReader()
        reader.readAsDataURL(blob) // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement('a')
          if (states == 1) {
            a.download = name + '.xlsx'
          } else {
            a.download = name + '.doc'
          }

          a.href = e.target.result
          // 修复firefox中无法触发click
          a.click()
        }
        Loading.close()
      } else {
        Loading.close()
        ElMessage.error('导出文件失败，请联系管理人员')
      }
    }
  } else {
    var xhr = new XMLHttpRequest()

    xhr.open('POST', url, true) // 也可以使用POST方式，根据接口

    xhr.responseType = 'blob' // 返回类型blob
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)

    xhr.onload = function () {
      // 请求完成
      if (this.status == 200 || this.status == 201) {
        // 返回201
        if (this.response.size < 150) {
          Loading.close()
          resetMessage({
            message: '暂无数据',
          })
          return false
        }
        var blob = this.response
        var reader = new FileReader()
        reader.readAsDataURL(blob) // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement('a')
          if (states == 1) {
            a.download = name + '.xlsx'
          }
          if (states == 2) {
            a.download = name + '.doc'
          }
          if (states == 3) {
            a.download = name + '.zip'
          }
          a.href = e.target.result
          // 修复firefox中无法触发click
          a.click()
        }
        Loading.close()
      } else {
        Loading.close()
        ElMessage.error('导出文件失败，请联系管理人员')
      }
    }
    xhr.setRequestHeader('Content-type', 'application/json')
    // 发送ajax请求
    xhr.send(JSON.stringify(params))
  }
}
export function DWON(url, method, data, name) {
  let Loading
  Loading = ElLoading.service({
    lock: true,
    fullscreen: true,
    text: '数据处理中，请稍后！！！',
  })
  // let roleType =
  //   window.localStorage.getItem('roleId') == '-1004' ? 'school' : 'leader'
  // //默认登录token
  let token = window.localStorage.getItem('token')
  var xhr = new XMLHttpRequest()
  xhr.open(method, url, true) // 也可以使用POST方式，根据接口
  xhr.responseType = 'blob' // 返回类型blob
  xhr.setRequestHeader('Authorization', 'Bearer ' + token)
  // xhr.setRequestHeader('roleType', roleType)
  // xhr.setRequestHeader('appCode', 'JSDATA')
  xhr.setRequestHeader('Content-Type', 'application/json')
  // 发送ajax请求
  if (method == 'POST') {
    xhr.send(JSON.stringify(data))
  } else {
    xhr.send()
  }

  xhr.onload = function () {
    // 请求完成
    if (this.status == 200) {
      // 返回201
      if (this.response.size < 200) {
        resetMessage({
          message: '暂无数据',
        })
        Loading.close()
        return false
      }
      var blob = this.response
      var reader = new FileReader()
      reader.readAsDataURL(blob) // 转换为base64，可以直接放入a标签href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement('a')
        a.download = name + '.xlsx'
        a.href = e.target.result
        // 修复firefox中无法触发click
        a.click()
      }
      Loading.close()
    }
  }
}
