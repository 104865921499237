<template>
  <div id="hnsmartedunav" v-if="$route.name!=='login'">
    <div id="hnsmartedunav-inner">
      <!-- <a href="https://portal.hasmartedu.com/tweb/pa/index.html" id="hnsmartedunav-title"> -->
      <div class="flex-center-start pointer" @click="navto('home')">
        <img id="hnsmartedunav-title-logo" src="~@/assets/imgs/logo.png" alt="绿色校园" style="width: 55px;object-fit: contain;"/>
        <span class="fz20 fw600 color-3 ml5">绿色校园</span>
      </div>
      <!-- </a> -->
      <div id="hnsmartedunav-body">
        <ul class="menu-inner flex-center">
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'home' ? 'menu-li-active' : ''" @click="navto('home')">
            <span class="menu-li-text fz16">首页</span>
          </li>
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'actzone' ? 'menu-li-active' : ''" @click="navto('actzone')">
            <span class="menu-li-text fz16">活动专区</span>
          </li>
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'course' ? 'menu-li-active' : ''" @click="navto('course')">
            <span class="menu-li-text fz16">耕种课程</span>
          </li>
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'plant' ? 'menu-li-active' : ''" @click="navto('plant')">
            <span class="menu-li-text fz16">绿植百科</span>
          </li>
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'article2' ? 'menu-li-active' : ''" @click="navto('article', 2)">
            <span class="menu-li-text fz16">劳动实践基地</span>
          </li>
          <li class="menu-li menu-li-hover pointer t-center mh16" :class="active === 'article1' ? 'menu-li-active' : ''" @click="navto('article', 1)">
            <span class="menu-li-text fz16">示范案例</span>
          </li>
        </ul>
      </div>
      <div id="hnsmartedunav-right">
        <el-button class="login__btn" @click="toLogin" round size="large">登录</el-button>
        <!-- <a
          id="hnsmartedunav-btn"
          href="https://sso.qq.com/open/logout?appid=801261&amp;redirect_uri=https%3A%2F%2Fsso.qq.com%2Fopen%2Foauth2%2Fauthorize%3Fappid%3D801261%26state%3D801261%26tenant_id%3D32%23%2Faccount"
        >
          <img src="https://portal.hasmartedu.com/tweb/pa/assets/uploadfile/images/n_avatar1.png" />
          <span>登录</span>
        </a>
        <a id="hnsmartedunav-btn" href="https://portal.hasmartedu.com/tweb/pa/download.html">
          <img src="https://portal.hasmartedu.com/tweb/pa/assets/uploadfile/images/n_tool_app.png" />
          <span>工具、APP中心</span>
        </a>
        <div id="hnsmartedunav-search-box">
          <input id="hnsmartedunav-search-input" type="text" placeholder="请输入关键字搜索新闻" />
          <img id="hnsmartedunav-search-btn" src="https://portal.hasmartedu.com/tweb/pa/assets/uploadfile/images/n_search.png" alt="搜索图标" />
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div id="nav-box">
    <ul id="nav-inner">
      <li class="nav-li nav-a-hover">
        <a href="https://portal.hasmartedu.com/tweb/pa/index.html" target="_blank" class="nav-a">
          <span class="nav-a-text">首页</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="https://train.hasmartedu.com/?appid=801266/" target="_blank" class="nav-a">
          <span class="nav-a-text">未来校园</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="" target="_blank" class="nav-a">
          <span class="nav-a-text">五育资源</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="" target="_blank" class="nav-a">
          <span class="nav-a-text">智慧教学</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="https://portal.hasmartedu.com/web/ai-homework.html#/" target="_blank" class="nav-a">
          <span class="nav-a-text">自主学习</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="https://khfw.hasmartedu.com:8091/#/course/home" target="_blank" class="nav-a">
          <span class="nav-a-text">课后服务</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="" target="_blank" class="nav-a">
          <span class="nav-a-text">综合评价</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="https://portal.hasmartedu.com/tweb/pa/app-list.html" target="_blank" class="nav-a">
          <span class="nav-a-text">应用中心</span>
        </a>
      </li>
      <li class="nav-li nav-a-hover">
        <a href="#" class="nav-a nav-a-active nav-ls">
          <span class="nav-a-text">绿色校园</span>
        </a>
      </li>
    </ul>
  </div> -->
  <div :class="$route.name!=='login' ? 'router-box' : ''"><router-view /></div>
  <footer id="hnsmartedufooter"  v-if="$route.name!=='login'">
    <div id="hnsmartedufooter-inner">
      <div class="flex-center-start" style="width: 240px;">
        <img id="hnsmartedunav-footer-logo" src="~@/assets/imgs/logo.png" alt="绿色校园"  style="width: 55px;object-fit: contain;"/>
        <span class="fz20 fw600 color-3 ml5">绿色校园</span>
      </div>
      <!-- <a id="hnsmartedufooter-title" href="https://portal.hasmartedu.com/tweb/pa/index.html" target="_self">
        <img id="hnsmartedunav-footer-logo" src="https://portal.hasmartedu.com/tweb/pa/assets/uploadfile/images/logo2.png" alt="河南省中小学智慧教育平台" />
      </a> -->
      <div id="hnsmartedufooter-body">
        <div>指导单位：河南省教育厅</div>
        <div>主办单位：河南省数字教育发展有限公司</div>
      </div>
      <div id="hnsmartedufooter-right">
        <div>客服电话：0371-87528141</div>
        <div><a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023027694号-1</a></div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import {ref, computed} from 'vue'
import {useRouter, useRoute} from 'vue-router'
const $router = useRouter()
const $route = useRoute()

const navto = (r:string, i?:number) => {
  if(i) {
    $router.replace({
      name: r,
      query: {id: i}
    })
  }else {
    $router.replace({
      name: r
    })
  }
}
const toLogin = () => {
  window.open('http://lsxy.51xiaoping.com/ulogin/', '_blank')
}
const active = computed(() => {
  return $route.query.id ? ($route.name as string) + ($route.query.id as string) : $route.name
})
</script>

<style scoped lang="scss">
.login__btn {
  color: #439F70;
  border-color: #439F70;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#hnsmartedunav,
#hnsmartedufooter {
  display: flex;
  justify-content: center;
}

#hnsmartedunav {
  position: relative;
  z-index: 1000;
  background: #fff;
}
#hnsmartedunav-inner {
  height: 78px;
}
#hnsmartedunav-inner,
#hnsmartedufooter-inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  min-width: 1080px;
  margin: 0 100px;
  color: #333;
}
#hnsmartedunav-body {
  flex: 1;
  margin: 0 20px;
}
#hnsmartedunav-right {
  display: flex;
}
#hnsmartedunav-btn {
  height: 36px;
  line-height: 34px;
  margin-right: 30px;
  border-radius: 4px;
  opacity: 0.8;
  font-size: 16px;
  color: #333;
  text-align: center;
  border: 1px solid #ffffff;
}
#hnsmartedunav-right img {
  vertical-align: middle;
}
#hnsmartedunav-btn + #hnsmartedunav-btn {
  margin-left: 15px;
}
#hnsmartedunav-btn {
  height: 36px;
  line-height: 34px;
  margin-right: 30px;
  border-radius: 4px;
  opacity: 0.8;
  font-size: 16px;
  color: #333;
  text-align: center;
  border: 1px solid #ffffff;
}
#hnsmartedunav-btn:hover {
  color: #0070ff;
}
#hnsmartedunav-search-box {
  position: relative;
  height: 40px;
  width: 240px;
}
#hnsmartedunav-search-input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 45px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}
#hnsmartedunav-search-input:focus {
  border-color: #0070ff;
}
#hnsmartedunav-search-btn {
  position: absolute;
  top: 2px;
  right: 5px;
  opacity: 0.8;
  cursor: pointer;
}
#nav-box {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  background: #2c76f5;
  box-shadow: 0px 4px 12px 1px rgba(0, 64, 154, 0.16);
}
#nav-inner {
  width: 100%;
  max-width: 1400px;
  min-width: 1080px;
  height: 55px;
  margin: 0 120px;
  display: flex;
  gap: 40px;
}
li {
  list-style: none;
  position: relative;
  min-width: 80px;
}
.nav-a-hover:hover {
  font-weight: 700;
}
.nav-a-hover:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 2px solid #fff;
}
.nav-a {
  color: #fff !important;
  border-bottom-color: #fff;
  display: block;
  line-height: 53px;
  font-size: 16px;
  color: #333;
  text-align: center;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
}
.nav-a-text {
  color: #fff !important;
}
.nav-a-active {
  font-weight: 700;
}
.nav-a-active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 2px solid #fff;
}
.nav-ls::before {
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 5px;
  background: url(./assets/imgs/lsxy_icon.png) center center no-repeat;
}
.router-box {
  // width: 1368px;
  // margin: 32px auto;
  position: relative;
}
#hnsmartedufooter {
  background: #eee;
}
#hnsmartedufooter-inner {
  height: 140px;
  color: #999;
}
hnsmartedunav *,
#hnsmartedufooter * {
  line-height: 1.4;
  box-sizing: border-box;
}
#hnsmartedufooter-body {
  margin: 0 136px;
}
.menu-inner {
  padding-inline-start: 0;
}
.menu-li {
  height: 78px;
  line-height: 78px;
}
.menu-li-hover:hover {
  font-weight: 700;
  color: #439F70;
}
.menu-li-hover:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 2px solid #439F70;
}
.menu-li-active {
  font-weight: 700;
  color: #439F70;
}
.menu-li-active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-bottom: 2px solid #439F70;
}
</style>
